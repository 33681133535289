<template>
  <div class="content-mail-cdg">
    <div class="data-mail-filiale-selected">
      <div v-if="error != null" class="error-message text-center ">
        <span class="error">{{ error }}</span>
      </div>
      <template v-if="error == null && loader == false && dataToUse != null">
        <!-- <b-row class="row">
          <b-col col xs="12" sm="12" md="12">
           Title  
            <b-form-group
              label="Titre"
              label-for="title-template-ht"
              class="input-modal-champ"
            >
              <b-form-input
                autocomplete="off"
                id="title-template-ht"
                v-model="dataToUse.client.title"
              ></b-form-input> </b-form-group
          ></b-col>
        </b-row> -->
        <b-form-checkbox
          v-if="dataToUse != null"
          switch
          class="check-style-custom-obligee-mail"
          v-model="dataToUse.send_to_client"
          value="yes"
          unchecked-value="no"
          disabled
        >
          <span class="title-check-obligee-mail"> Client</span></b-form-checkbox
        >
        <b-row>
          <b-col col xs="12" sm="12" md="12">
            <!--  Mail  -->
            <b-form-group
              label="Email"
              label-for="mail-template-ht"
              class="input-modal-champ"
            >
              <b-form-input
                autocomplete="off"
                id="mail-template-ht"
                v-model="dataToUse.client.email"
                type="email"
              ></b-form-input> </b-form-group
          ></b-col>
        </b-row>
        <b-row cols="2" class="mr-1">
          <b-col>
            <b-form-group
              label="Extra mail"
              label-for="extra-mail-template-select"
              v-if="dataToUse"
            >
            </b-form-group
          ></b-col>
          <b-col>
            <b-button
              @click="handleModalAddExtraMail(dataToUse.client, 'client')"
              class="button-add-mail"
              ><font-awesome-icon icon="plus"/></b-button
          ></b-col>
        </b-row>
        <b-row class="row mb-2 mr-1">
          <b-col col xs="12" sm="12" md="12">
            <!-- Extra mail  -->
            <multiselect
              v-model="dataToUse.client.extra_mails"
              :options="dataToUse.client.extra_mails_list"
              :multiple="true"
              required
              class="multiselect-vue-custom-style-theme"
              :showLabels="false"
              :limit="1"
              :limit-text="limitText"
            >
            </multiselect>
          </b-col>
        </b-row>
        <b-form-checkbox
          v-if="dataToUse != null"
          switch
          class="check-style-custom-obligee-mail"
          v-model="dataToUse.send_to_vendeur"
          value="yes"
          unchecked-value="no"
        >
          <span class="title-check-obligee-mail">
            Sociéte</span
          ></b-form-checkbox
        >
        <b-row>
          <b-col col xs="12" sm="12" md="12">
            <!--  Mail  -->
            <b-form-group
              label="Email"
              label-for="mail-template-ht"
              class="input-modal-champ"
            >
              <b-form-input
                autocomplete="off"
                id="mail-template-ht"
                v-model="dataToUse.vendeur.email"
                type="email"
              ></b-form-input> </b-form-group
          ></b-col>
        </b-row>
        <b-row cols="2" class="mr-1">
          <b-col>
            <b-form-group
              label="Extra mail"
              label-for="extra-mail-template-select"
              v-if="dataToUse"
            >
            </b-form-group
          ></b-col>
          <b-col>
            <b-button
              @click="handleModalAddExtraMail(dataToUse.vendeur, 'vendeur')"
              class="button-add-mail"
              ><font-awesome-icon icon="plus"/></b-button
          ></b-col>
        </b-row>
        <b-row class="row mb-2 mr-1">
          <b-col col xs="12" sm="12" md="12">
            <!-- Extra mail  -->
            <multiselect
              v-model="dataToUse.vendeur.extra_mails"
              :options="dataToUse.vendeur.extra_mails_list"
              :multiple="true"
              required
              class="multiselect-vue-custom-style-theme"
              :showLabels="false"
              :limit="1"
              :limit-text="limitText"
            >
            </multiselect>
          </b-col>
        </b-row>
      </template>
    </div>
    <div class="template-mail-filiale-selected">
      <b-row class="row">
        <!-- Body -->
        <b-col col xs="12" sm="12" md="12">
          <editor
            v-model="dataToUse.html"
            :init="computedDataTinymce.init"
          ></editor>
        </b-col>
      </b-row>
    </div>

    <b-modal
      ref="ModalAddExtraMail"
      id="ModalAddExtraMail"
      :hide-footer="true"
      :hide-header="true"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Ajouter un Extra Mail</div>
        <div class="iconClose" @click.prevent.stop="hideModalAddExtraMail()">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <div class="ligne-box-setting-header mt-1"></div>
      <form class="form-modal-custom-style">
        <div class="mt-4 ml-3">
          <b-form-group
            label="Extra Mail *"
            label-for="extraMail"
            class="input-modal-champ"
          >
            <b-form-input
              id="Extra Mail"
              v-model="email"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="actionModel">
          <div class="messageError">
            <div v-if="errors" class="error">
              <ul v-if="Array.isArray(errors)">
                <li
                  class="message-error"
                  v-for="(e, index) in errors"
                  :key="'error' + index"
                >
                  {{ e }}
                </li>
              </ul>
              <div v-else class="message-error">{{ errors }}</div>
            </div>
            <b-button
              type="submit"
              class="button-valide-style mt-1"
              @click.prevent.stop="createExtraMail()"
            >
              <span>
                Valider
                <div v-if="loading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script src="//cdn.tinymce.com/4/tinymce.min.js"></script>
<script>
import Editor from '@tinymce/tinymce-vue';

import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      email: null,
      errors: null,
      loading: false,
      userSelectedExtraMail: null
    };
  },
  props: {
    loader: { default: false },
    dataToUse: { required: true },
    error: { default: null }
  },
  methods: {
    ...mapActions(['setLisImage', 'addExtraMail']),
    limitText(count) {
      return `et  ${count} autre extra email`;
    },
    handleModalAddExtraMail(user, type) {
      this.userSelectedExtraMail = { ...user, typeUser: type };
      this.$refs['ModalAddExtraMail'].show();
    },
    hideModalAddExtraMail() {
      this.$refs['ModalAddExtraMail'].hide();
      this.email = null;
      this.errors = null;
      this.userSelectedExtraMail = null;
    },
    async createExtraMail() {
      this.errors = null;
      this.loading = true;
      let extraMail = new FormData();
      extraMail.append('filiale_id', this.userSelectedExtraMail.id);
      extraMail.append('email', this.email);
      const response = await this.addExtraMail(extraMail);
      if (response.success == true) {
        this.loading = false;
        this.dataToUse[
          this.userSelectedExtraMail.typeUser
        ].extra_mails_list.push(response.result.email);
        this.dataToUse[this.userSelectedExtraMail.typeUser].extra_mails.push(
          response.result.email
        );
        this.hideModalAddExtraMail();
      } else {
        this.loading = false;
        this.errors = response.result;
      }
    }
  },
  computed: {
    ...mapGetters(['getImageTemplateList']),
    computedImageList() {
      return this.getImageTemplateList.map(item => {
        return { title: item.title, value: item.url };
      });
    },
    computedDataTinymce() {
      return {
        init: {
          selector: '#editor',
          init_instance_callback: function(editor) {
            var freeTiny = document.querySelector(
              '.tox-notifications-container'
            );
            if (freeTiny) {
              freeTiny.style.display = 'none';
            }
          },
          fullpage_default_doctype: '<!DOCTYPE html>',
          fullpage_default_encoding: 'UTF-8',
          plugins: [
            'advlist autolink lists link image  print preview hr',
            'searchreplace visualblocks code ',
            'insertdatetime  table paste imagetools charmap'
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt',

          toolbar_mode: 'floating',
          powerpaste_allow_local_images: true,
          language: 'fr_FR',
          toolbar:
            ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify |  link image |' +
            ' bullist  numlist outdent indent  |  forecolor backcolor  |' +
            '   print preview  fullpage |' +
            ' hr charmap',
          menubar: 'favs file edit view insert format   ',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat '
            }
          },
          contextmenu: false,
          removed_menuitems: 'paste pastetext',
          block_formats: 'box=alert-box; badge=badge; footer=footer',
          formats: {
            'alert-box': {
              block: 'div',
              styles: {
                'background-color': '#f4f5f9',
                padding: '20px'
              }
            },
            footer: {
              block: 'div',
              styles: {
                'background-color': ' #c7c7c7',
                padding: '20px'
              }
            },
            badge: {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '.1rem solid transparent',
                'border-radius': '50px',
                padding: '1rem',
                'margin-top': '0px',
                'margin-bottom': '1rem',
                color: '#2276d2',
                'box-shadow': '2px 3px #d6d7da',
                'background-color': '#008cd6',
                color: 'white'
              }
            }
          },
          /* enable title field in the Image dialog*/
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          image_list: success => success(this.computedImageList),
          file_picker_types: 'image',
          images_upload_handler: async (blobInfo, success, failure) => {
            let param = new FormData();
            param.append('image', blobInfo.blob());
            this.setLisImage(param)
              .then(response => {
                success(response.data.data.url);
              })
              .catch(error => {
                failure('HTTP Error: ' + error.message);
              });
          }
        }
      };
    }
  },
  components: {
    Editor
  }
};
</script>

<style lang="scss" scoped>
.content-mail-cdg {
  height: calc(100vh - 120px);
  display: flex;
  .data-mail-filiale-selected {
    width: 30%;
  }
  .template-mail-filiale-selected {
    width: 70%;
  }
}
.button-add-mail {
  font-size: 10px;
  border: none;
  float: right;
  color: #fff;
  background: #8d8cb7;
  border-radius: 50px;
}
.message-error {
  color: red;
  list-style: none;
}
.title-check-obligee-mail {
  color: #4d4bac;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  //   margin-bottom: 10px;
}
.label-item-custom-mail-obligee {
  font-size: 9px;
}
</style>
<style lang="scss">
.check-style-custom-obligee-mail {
  text-align-last: center;
  margin-bottom: 5px;
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #5f5db5;
    background-color: #5f5db5;
  }
}
.content-mail-cdg .tox-tinymce {
  height: calc(100vh - 153px) !important;
}
</style>
